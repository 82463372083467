import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { EdittingTradingRobot, Tradingrobot } from 'features/api/chart/schemas';

interface Tradingrobots {
  edittingRobot: EdittingTradingRobot | null;
  authOrigin: string | null;
  startingRobot: Tradingrobot | null;
  stoppingRobot: Tradingrobot | null;
}

export const initialTradingrobots: Tradingrobots = {
  edittingRobot: null,
  authOrigin: null,
  startingRobot: null,
  stoppingRobot: null,
};

const _setEdittingRobot: CaseReducer<
  Tradingrobots,
  PayloadAction<EdittingTradingRobot>
> = (state, action) => {
  const { strategy_id: strategyId, name } = action.payload;
  state.edittingRobot = { strategy_id: strategyId, name };
};

const _resetEdittingRobot: CaseReducer<Tradingrobots> = (state) => {
  state.edittingRobot = null;
};

const _setAuthOrigin: CaseReducer<Tradingrobots, PayloadAction<string>> = (
  state,
  action,
) => {
  const authOrigin = action.payload;
  state.authOrigin = authOrigin;
};

const _setStartingRobot: CaseReducer<
  Tradingrobots,
  PayloadAction<Tradingrobot>
> = (state, action) => {
  state.startingRobot = { ...action.payload };
};

const _resetStartingRobot: CaseReducer<Tradingrobots> = (state) => {
  state.startingRobot = null;
};

const _setStoppingRobot: CaseReducer<
  Tradingrobots,
  PayloadAction<Tradingrobot>
> = (state, action) => {
  state.stoppingRobot = { ...action.payload };
};

const _resetStoppingRobot: CaseReducer<Tradingrobots> = (state) => {
  state.stoppingRobot = null;
};

// MEMO: 현재 트레이딩로봇은 보유는 여러 개 할 수 있지만 작동은 하나만 할 수 있음.
export const tradingrobotSlice = createSlice({
  name: 'tradingrobot',
  initialState: initialTradingrobots,
  reducers: {
    setEdittingRobot: _setEdittingRobot,
    resetEdittingRobot: _resetEdittingRobot,
    setAuthOrigin: _setAuthOrigin,
    setStartingRobot: _setStartingRobot,
    resetStartingRobot: _resetStartingRobot,
    setStoppingRobot: _setStoppingRobot,
    resetStoppingRobot: _resetStoppingRobot,
  },
});

export const {
  setEdittingRobot,
  resetEdittingRobot,
  setAuthOrigin,
  setStartingRobot,
  resetStartingRobot,
  setStoppingRobot,
  resetStoppingRobot,
} = tradingrobotSlice.actions;
