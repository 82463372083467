import { Tradingrobot } from 'features/api/chart/schemas';
import { Price } from 'features/api/coinone';

import { useGetMarketCurrentPrices } from './socket/useGetMarketCurrentPrices';

export const useTradingrobotMetrics = ({
  trading,
  initialPrices,
}: {
  trading: Tradingrobot;
  initialPrices: Price[];
}) => {
  const { markets, principal } = trading;
  const market = markets[0];
  const {
    name,
    buy_count: count,
    buy_notional_value: buyNotionalValue,
    buy_fee: fee,
    real_pnl: realPnl,
  } = market;

  let currentPrices = useGetMarketCurrentPrices([name.replace('KRW-', '')]);

  // 웹소켓 연결이 안되어 있을 때, 초기 가격으로 계산
  if (Object.keys(currentPrices).length === 0) {
    const findPrice = (currency: string) =>
      initialPrices.find((price) => currency in price) as Record<
        string,
        number
      >;
    currentPrices = findPrice(name.replace('KRW-', ''));
  }

  const evalNotionalValue =
    currentPrices[name.replace('KRW-', '')] * Number(count);
  const evalPnl =
    evalNotionalValue - Number(buyNotionalValue) - Number(fee) || 0;
  const evalRor =
    (evalPnl / (Number(buyNotionalValue) + Number(fee))) * 100 || 0;
  const pnl = Number(realPnl) + evalPnl;
  const ror = (pnl / Number(principal)) * 100;
  const evalBalance = Number(principal) + pnl;

  return { evalPnl, evalRor, pnl, ror, evalBalance };
};
