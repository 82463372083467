import { logicalOperators, LogicalOperator, CandleKind } from 'constants/';

import {
  Formula as FormulaClient,
  MarketIndicatorInfo,
  StrategyItem,
} from 'features/schemas/client';
import {
  StrategyExprs,
  Formula as FormulaServer,
} from 'features/schemas/server/';
export const addBuyStrategyToPreviewExprs = (
  formulas: FormulaClient[],
  buyStrategy: StrategyItem[],
  previewCandle: CandleKind,
): StrategyExprs => {
  return buyStrategy.map((element) => {
    if (logicalOperators.includes(element)) {
      return element as LogicalOperator;
    } else {
      // formulas 배열 내의 요소의 candleKind 키를 kind로 변경
      const formula = formulas.find((formula) => formula.name === element);

      if (formula) {
        const { name, tokens } = formula;

        const convertedTokens = tokens.map((token) => {
          if (typeof token === 'object') {
            const { indicator, shift, market, ...rest } =
              token as MarketIndicatorInfo;
            const marketText = market ? market.replaceAll('KRW-', '') : null;
            return {
              ...rest,
              name: indicator.name,
              shift: shift - 1,
              market: marketText ? `KRW-${marketText}` : null,
            };
          } else {
            return token;
          }
        });
        return {
          name,
          tokens: convertedTokens,
          kind: previewCandle,
        } as FormulaServer;
      }
    }
  }) as StrategyExprs;
};
