import { useRouter } from 'next/router';

import { useEffect, useState } from 'react';

import { routes } from 'constants/';
import { useSession } from 'next-auth/react';

import { useAppDispatch } from 'store';

import strategyQueries from 'hooks/useQuery/strategy';

import api from 'features/api/chart/strategy';

import {
  BacktestingWithConfig,
  BacktestingAndStrategy,
} from 'features/schemas/server';
import { setEdittingRobot } from 'features/slices';

import Button from 'components/Button';

import ConfirmStrategyImportModal from 'components/Modal/ConfirmStrategyImportModal/ConfirmStrategyImportModal';
import {
  ImportStrategyModalHeaderRow,
  ImportStrategyModalMarketRow,
} from 'components/Modal/ImportStrategyModal/ImportStrategyModalRow';
import { ModalKind, SelectValueModalProps } from 'components/Modal/ModalTypes';

import SelectModal from 'components/Modal/SelectModal/SelectModal';

import Spinner from 'components/Spinner';

import styles from 'components/Modal/ImportStrategyModal/ImportStrategyModal.module.scss';

type ImportStrategyModalProps = Omit<
  SelectValueModalProps<BacktestingAndStrategy>,
  'onSubmit'
>;

type ImportBoardStrategyModalProps = ImportStrategyModalProps & {
  forBoard?: boolean;
  // 로봇 생성할 땐 백테스팅인 완료됐던 전략만 담을 수 있기 때문에
  hasBacktestingResultOnly?: boolean;
};

const ImportStrategyModal = ({
  onSelect,
  onClose,
  hasBacktestingResultOnly = false, // true면 로봇 생성 모드
}: ImportBoardStrategyModalProps) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { data: session } = useSession();
  const [strategyId, setStrategyId] = useState<string | undefined>(undefined);
  // 로봇 생성 시 필요
  const [edittingRobotStrategyId, setEdittingRobotStrategyId] = useState<
    string | undefined
  >(undefined);

  const { isLoading: isStrategyDetailLoading, data: strategyDetail } =
    strategyQueries.useBacktestingAndStrategy({
      backtestingId: strategyId,
    });

  const {
    isLoading,
    isSuccess,
    data: strategyList = [],
  } = strategyQueries.useAllBacktestings({
    username: session?.username,
  });

  const filterList = (strategyList: BacktestingWithConfig[]) =>
    strategyList.filter((strategy) => {
      if (hasBacktestingResultOnly) {
        return Boolean(strategy.ror);
      }
      return true;
    });

  const filteredStrategyListForRobot = filterList(strategyList);
  const filteredStrategyListForBacktesting = filterList(strategyList).filter(
    (strategy) => strategy.author === session?.username,
  );

  useEffect(() => {
    if (strategyDetail && !Boolean(strategyDetail.ror)) {
      onSelect(strategyDetail);
    }
  }, [onSelect, strategyDetail]);

  useEffect(() => {
    (async () => {
      if (!edittingRobotStrategyId) {
        return;
      }

      if (
        filteredStrategyListForBacktesting.find(
          (x) => x.strategy_id === edittingRobotStrategyId,
        )
      ) {
        const detail = await api.readBacktestingAndStrategy(
          edittingRobotStrategyId,
        );

        dispatch(setEdittingRobot(detail));
        onClose();
        return;
      }

      const detail = await api.readPublicBacktestingAndStrategy(
        edittingRobotStrategyId,
      );

      dispatch(setEdittingRobot(detail));
      onClose();
    })();
  }, [
    onClose,
    dispatch,
    edittingRobotStrategyId,
    strategyId,
    filteredStrategyListForBacktesting,
  ]);

  const handleStrategySelect = async (backtesting: BacktestingWithConfig) => {
    if (isStrategyDetailLoading && strategyId! && !hasBacktestingResultOnly) {
      return;
    }

    // 로봇 생성 시
    if (hasBacktestingResultOnly) {
      setEdittingRobotStrategyId(backtesting.strategy_id);
      return;
    }

    setStrategyId(backtesting.strategy_id);
  };

  const handleCancel = () => {
    setStrategyId(undefined);
  };

  const handleConfirm = (backtestingAndStrategy: BacktestingAndStrategy) => {
    onSelect(backtestingAndStrategy);
  };

  const handleMarketplaceClick = () => {
    router.push(routes.marketplaceRoute);
  };

  return (
    <SelectModal
      title={
        hasBacktestingResultOnly
          ? '자동매매 전략을 선택해주세요'
          : '불러올 전략을 선택해 주세요'
      }
      onClose={onClose}
      width={1000}
      height={'auto'}
    >
      {!hasBacktestingResultOnly &&
        strategyDetail &&
        Boolean(strategyDetail.ror) && (
          <div className={styles.confirmModalContainer}>
            <ConfirmStrategyImportModal
              kind={ModalKind.ConfirmStrategyImportModal}
              backtestingAndStrategy={strategyDetail}
              onClose={handleCancel}
              onConfirm={handleConfirm}
            />
          </div>
        )}
      <div className={styles.root}>
        <div className={styles.rowContainer}>
          {(!hasBacktestingResultOnly &&
            filteredStrategyListForBacktesting.length > 0) ||
            (hasBacktestingResultOnly &&
              filteredStrategyListForRobot.length > 0 && (
                <ImportStrategyModalHeaderRow />
              ))}
          {isLoading ? (
            <div className={styles.spinnerContainer}>
              <Spinner />
            </div>
          ) : (
            <div className={styles.rowListWrapper}>
              {(hasBacktestingResultOnly
                ? filteredStrategyListForRobot
                : filteredStrategyListForBacktesting
              ).map((strategy) => {
                return (
                  <ImportStrategyModalMarketRow
                    key={strategy.id}
                    backtesting={strategy}
                    handleSelect={() => handleStrategySelect(strategy)}
                  />
                );
              })}
            </div>
          )}
        </div>
        {isSuccess &&
          hasBacktestingResultOnly &&
          filteredStrategyListForRobot.length === 0 && (
            <div className={styles.noResult}>
              <div>
                <p>백테스팅이 완료된 전략이 없어요.</p>
                <p>전문 퀀트 트레이더가 올린 전략을 구경해보실래요?</p>
              </div>
              <div>
                <Button
                  theme="primary1"
                  size="large"
                  onClick={handleMarketplaceClick}
                >
                  전략 구경하러 가기
                </Button>
              </div>
            </div>
          )}
        <div></div>
      </div>
    </SelectModal>
  );
};

export default ImportStrategyModal;
