// '/strategy' - GET, POST
// '/strategy/{pk}' - GET, DELETE, PUT
// '/strategy/public' - GET
// '/strategy/public/{pk}' - GET, DELETE, POST
// '/strategy/public/{pk}/following' - DELETE, POST
import { chartAPI } from 'features/api/chart/base';
import { IncompleteBacktestingForm } from 'features/api/chart/schemas';
import { BoardForm } from 'features/api/chart/schemas/form';
import { BacktestingForm } from 'features/schemas/server/';

// 현재 로그인한 계정으로 새 백테스팅 생성
const createBacktestingAndStrategy = async (
  data: BacktestingForm | IncompleteBacktestingForm,
): Promise<string> => {
  const response = await chartAPI.post(`/strategy`, data);
  return response.data;
};

// 현재 로그인한 계정의 모든 백테스팅을 조회
const readAllBacktestingsAndStrategies = async () => {
  const response = await chartAPI.get('/strategy');
  return response.data;
};

// 현재 로그인한 계정의 백테스팅 아이디와 일치하는 백테스팅을 조회
const readBacktestingAndStrategy = async (backtestingId?: string) => {
  if (!backtestingId) {
    return null;
  }
  const response = await chartAPI.get(`/strategy/${backtestingId}`);
  if (response.status < 400) {
    if (response.data.config && response.data.config.markets === null) {
      // 서버가 종목 설정이 안되어있는 경우 null 로 내려줌. 이 경우 빈 배열로 바꿔줌.
      response.data.config.markets = [];
    }
    return response.data;
  }
  return null;
};

// 현재 로그인한 계정의 백테스팅 아이디와 일치하는 백테스팅을 삭제
const deleteBacktestingAndStrategy = async (backtestingId: string) => {
  await chartAPI.delete(`/strategy/${backtestingId}`);
};

// 현재 로그인한 계정의 백테스팅 아이디와 일치하는 백테스팅을 업데이트
const updateBacktestingAndStrategy = async (
  backtestingId: string,
  data: BacktestingForm | IncompleteBacktestingForm,
): Promise<string> => {
  const response = await chartAPI.put(`/strategy/${backtestingId}`, data);
  return response.data;
};

// 모든 사람들의 공개된 백테스팅을 조회
const readAllPublicBacktestings = async () => {
  const response = await chartAPI.get('/strategy/public');
  return response.data;
};

// 모든 사람들의 공개된 백테스팅에서 백테스팅 아이디와 일치하는 백테스팅을 조회
const readPublicBacktestingAndStrategy = async (backtestingId?: string) => {
  if (!backtestingId) {
    return null;
  }
  const response = await chartAPI.get(`/strategy/public/${backtestingId}`);
  return response.data;
};

// 현재 로그인한 계정의 백테스팅에서 백테스팅 아이디와 일치하는 것을 공개함
const makeBacktestingAndStrategyPublic = async ({
  strategyId,
  data,
}: {
  strategyId: string;
  data: BoardForm;
}) => {
  const response = await chartAPI.post(`/strategy/public/${strategyId}`, data);
  return response.data;
};

// 현재 로그인한 계정의 백테스팅에서 백테스팅 아이디와 일치하는 것을 비공개함
const makeBacktestingAndStrategyPrivate = async (backtestingId: string) => {
  const response = await chartAPI.delete(`/strategy/public/${backtestingId}`);
  return response;
};

// 해당 백테스팅을 팔로우함
const followBacktesting = async (backtestingId: string) => {
  const response = await chartAPI.put(
    `/strategy/public/${backtestingId}/following`,
  );
  return response;
};

// 해당 백테스팅을 팔로우를 취소함
const unfollowBacktesting = async (backtestingId: string) => {
  const response = await chartAPI.delete(
    `/strategy/public/${backtestingId}/following`,
  );
  return response;
};

const updateOrCreateBacktestingAndStrategy = async (
  id: string | null,
  data: BacktestingForm | IncompleteBacktestingForm,
): Promise<string | null> => {
  const form = Object.assign({}, data);

  const { config } = form;
  if (!config.excluded_markets?.length) {
    delete config.excluded_markets;
  }

  if (!config.markets?.length) {
    delete config.markets;
  }

  if (!config.principal) {
    delete config.principal;
  }

  if (id) {
    return await updateBacktestingAndStrategy(id, form);
  }
  return await createBacktestingAndStrategy(data);
};

const cloneBacktestingAndStrategy = async (backtestingId: string) => {
  const response = await chartAPI.post(`/strategy/${backtestingId}/clone`);

  return response;
};

export default {
  createBacktestingAndStrategy,
  readAllPublicBacktestings,
  readPublicBacktestingAndStrategy,
  updateBacktestingAndStrategy,
  deleteBacktestingAndStrategy,
  readAllBacktestingsAndStrategies,
  readBacktestingAndStrategy,
  makeBacktestingAndStrategyPublic,
  makeBacktestingAndStrategyPrivate,
  followBacktesting,
  unfollowBacktesting,
  updateOrCreateBacktestingAndStrategy,
  cloneBacktestingAndStrategy,
};
