import Link from 'next/link';

import { useRouter } from 'next/router';

import clsx from 'clsx';
import { routes } from 'constants/';
import AssetIcon from 'images/icons/svgs/ic-asset.svg';
import BlogIcon from 'images/icons/svgs/ic-blog.svg';
import RobotIcon from 'images/icons/svgs/ic-robot.svg';
import SettingsIcon from 'images/icons/svgs/ic-setting.svg';
import StrategyIcon from 'images/icons/svgs/ic-strategy.svg';
import { useSession } from 'next-auth/react';

import { amplitudeEventNames, trackEvent } from 'features/amplitude';

import styles from 'components/Navigation/MobileNavigation.module.scss';

const MobileNavigation = () => {
  const router = useRouter();
  const { data: session } = useSession();

  if (!session) {
    return null;
  }

  const menuItems = [
    {
      name: '로봇',
      icon: <RobotIcon />,
      link: routes.tradingrobotRoute(session.username),
      pathname: '/user/[username]/tradingrobot',
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_ROBOT);
      },
    },
    {
      name: '전략',
      icon: <StrategyIcon />,
      link: routes.marketplaceRoute,
      pathnames: ['/user/[username]/strategy', routes.marketplaceRoute],
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_STRTG);
      },
    },
    {
      name: '자산',
      icon: <AssetIcon />,
      link: routes.myAssetPageRoute(session.username),
      pathname: '/user/[username]/asset',
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_ASSET);
      },
    },
    {
      name: '블로그',
      icon: <BlogIcon />,
      link: routes.blogRoute,
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_BT_BLOG);
      },
    },
    {
      name: '더보기',
      icon: <SettingsIcon />,
      link: routes.myPageRoute(session.username),
      pathname: '/user/[username]',
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_MYPAGE);
      },
    },
  ];

  return (
    <nav className={styles.root}>
      <ul className={styles.navItems}>
        {menuItems.map(({ name, icon, link, pathname, pathnames, onClick }) => {
          return (
            <li
              className={clsx(
                styles.navItem,
                (link === router.pathname ||
                  pathname === router.pathname ||
                  pathnames?.includes(router.pathname)) &&
                  styles.activeTab,
              )}
              key={name}
              onClick={onClick}
            >
              <Link href={link}>
                <a>
                  {icon}
                  <span>{name}</span>
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default MobileNavigation;
